const PASSED = "PASSED";
const FAILED = "FAILED";
const NA = "NA";
const NOT_ANSWERED = "NOT_ANSWERED";
const REPLACE = "1.0";
const REPAIR = "2.0";
const ADJUST = "3.0";
const OTHER = "4.0";
const FAILED_OPTION_NOT_ANSWERED = "0.0";

export default {
    PASSED,
    FAILED,
    NA,
    NOT_ANSWERED,
    REPLACE,
    REPAIR,
    ADJUST,
    OTHER,
    FAILED_OPTION_NOT_ANSWERED
};