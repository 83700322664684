const DOOR_WIDTH = 261;
const DOOR_HEIGHT = 650;

export const LEFT_DOOR_MARKERS = [
    {
        id: 548,
        top: DOOR_HEIGHT / 1.51,
        left: DOOR_WIDTH / 2.45
    },
    {
        id: 549,
        top: DOOR_HEIGHT / 4.47,
        left: DOOR_WIDTH / -48.52
    },
    {
        id: 550,
        top: DOOR_HEIGHT / 72.72,
        left: DOOR_WIDTH / 6.92
    },
    {
        id: 551,
        top: DOOR_HEIGHT / 2.21,
        left: DOOR_WIDTH / -27.72
    },
    {
        id: 552,
        top: DOOR_HEIGHT / 2.09,
        left: DOOR_WIDTH / 1.37
    },
    {
        id: 553,
        top: DOOR_HEIGHT / 1.27,
        left: DOOR_WIDTH / 1.2
    },
    {
        id: 572,
        top: DOOR_HEIGHT / -20,
        left: DOOR_WIDTH / 2.23
    },
    {
        id: 555,
        top: DOOR_HEIGHT / 4.68,
        left: DOOR_WIDTH / 1.61
    },
    {
        id: 556,
        top: DOOR_HEIGHT / 1.07,
        left: DOOR_WIDTH / 2.55
    },
    {
        id: 571,
        top: DOOR_HEIGHT / 4.44,
        left: DOOR_WIDTH / 4.07
    },
    {
        id: 554,
        top: DOOR_HEIGHT / 1.51,
        left: DOOR_WIDTH / -51.1
    }
];

export const RIGHT_DOOR_MARKERS = [
    {
        id: 548,
        top: DOOR_HEIGHT / 1.51,
        left: DOOR_WIDTH / 2.45
    },
    {
        id: 549,
        top: DOOR_HEIGHT / 4.47,
        left: DOOR_WIDTH / 1.31
    },
    {
        id: 550,
        top: DOOR_HEIGHT / 72.72,
        left: DOOR_WIDTH / 1.69
    },
    {
        id: 551,
        top: DOOR_HEIGHT / 2.21,
        left: DOOR_WIDTH / 1.27
    },
    {
        id: 552,
        top: DOOR_HEIGHT / 2.09,
        left: DOOR_WIDTH / 173
    },
    {
        id: 553,
        top: DOOR_HEIGHT / 1.27,
        left: DOOR_WIDTH / -12.81
    },
    {
        id: 572,
        top: DOOR_HEIGHT / -20,
        left: DOOR_WIDTH / 3.46
    },
    {
        id: 555,
        top: DOOR_HEIGHT / 4.68,
        left: DOOR_WIDTH / 8.44
    },
    {
        id: 556,
        top: DOOR_HEIGHT / 1.07,
        left: DOOR_WIDTH / 2.55
    },
    {
        id: 571,
        top: DOOR_HEIGHT / 4.44,
        left: DOOR_WIDTH / 2.035
    },
    {
        id: 554,
        top: DOOR_HEIGHT / 1.51,
        left: DOOR_WIDTH / 1.315
    }
];