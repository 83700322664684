import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

export const NotAuthenticated = () => {
    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;

    return user ? (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <h2>You do not have a valid NAPFIS Membership.</h2>
                <p style={{ textAlign: 'center' }}>To access the Door Inspection App you will need to purchase or renew a membership. You can do this from our website at <a href="https://www.napfis.co.uk">www.napfis.co.uk</a>. Please log into your account and follow the instructions in the Member Subscription section.</p>
            </Content>
            <BottomNav />
        </Fragment>
    ) : <Redirect to="/login" />
}
