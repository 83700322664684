import React from 'react';
import { Route } from 'react-router-dom';
import { NotAuthenticated } from '../NotAuthenticated';

const ProtectedRoute = ({ isAuthenticated, component:Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render = {() => isAuthenticated ? (
                <Component />
            ) : (
                <NotAuthenticated />
            )}
        />
    );
};

export default ProtectedRoute;