import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import { closeMenu, sendHelpData } from '../../actions/appActions';
import { CLEAR_SEND_DATA } from '../../types/appTypes';

export const Help = () => {
    const dispatch = useDispatch();
    const appSelector = useSelector((state) => state.app);
    const { helpLoading, helpErrors, helpSuccess } = appSelector;

    const handleSendData = () => {
        dispatch(closeMenu());
        dispatch(sendHelpData());
    }

    useEffect(() => {
        dispatch(closeMenu());
        return () => {
            dispatch({ type: CLEAR_SEND_DATA });
        }
    }, []);

    return (
        <Fragment>
            <Header>Help</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                {helpSuccess ? (
                    <p style={{ textAlign: 'center', marginTop: '0.5rem' }}>You've successfully uploaded your application data.</p>
                ) : (
                    <>
                        <p style={{ textAlign: 'center' }}>If you are expereincing a problem with this application, please click on the 'Report a Fault' button below. We'll be in touch to investigate the issue</p>
                        <div className="form__buttons">
                            <button className="form__button" onClick={handleSendData} type="button" disabled={helpLoading}>Report a Fault <PulseLoader loading={helpLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>{helpErrors}</div>
                    </>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
