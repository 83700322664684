import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Localbase from 'localbase';

import Header from '../layout/Header';
import Menu from '../layout/Menu';
import BottomNav from '../layout/BottomNav';
import Content from '../layout/Content';
import { DoorViewQuestion } from '../DoorViewQuestion';

import useDebounce from '../../hooks/useDebounce';
import { saveSection, addDoor, saveRef, saveType, removeDoor, restoreDoor, savePhoto, removePhoto, saveOther, saveDoorToLocalStorage, saveFailedOption, saveFailedOther } from '../../actions/doorActions';

import styles from './styles/DoorView.module.css';
import answers from '../../constants/answers';
import { LEFT_DOOR_MARKERS, RIGHT_DOOR_MARKERS } from '../../constants/dimensions';
import { removeDoorPhoto, saveDoorPhoto } from '../../functions/utils';
import doorSections from '../../constants/doorSections';
import { DOOR_LEFT, DOOR_LEFT_NEXT, DOOR_RIGHT, DOOR_RIGHT_NEXT, PHOTOGRAPH_ONE, PHOTOGRAPH_TWO } from '../../constants/images';

export const DoorView = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    const doorSelector = useSelector(state => state.door);
    const { doors, otherLoading, saveToLocalStorage } = doorSelector;

    const doorsArray = Object.values(doors);

    const doorKeys = doorsArray.filter((door) => {
        return !door.deleted;
    }).map((door) => door.id);
    
    const appSelector = useSelector(state => state.app);
    const { sections } = appSelector;

    const surveySelector = useSelector(state => state.survey);
    const { id: surveyId } = surveySelector;
    
    const currentDoor = doors[id];

    console.log(currentDoor);
    const isRightDoor = currentDoor?.leftId ? true : false;
    const doorAnswers = currentDoor?.answers;

    const [doorRef, setDoorRef] = useState(currentDoor?.ref === id ? '' : currentDoor?.ref);
    const [doorType, setDoorType] = useState(currentDoor?.type || '');
    const [activeSection, setActiveSection] = useState(null);
    const [showControls, setShowControls] = useState(false);
    const [finishDoor, setFinishDoor] = useState(false);
    const [nextDoor, setNextDoor] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [otherInfo, setOtherInfo] = useState(false);
    const [otherText, setOtherText] = useState(currentDoor.other.text);
    const [otherCost, setOtherCost] = useState(currentDoor.other.cost);
    const [firstPhotoPreview, setFirstPhotoPreview] = useState('');
    const [secondPhotoPreview, setSecondPhotoPreview] = useState('');

    let rightDoorID = null;
    let leftDoorID = null;
    let rightDoor = Object.values(doors).find((door) => door.leftId === id && !door.deleted);

    if (rightDoor) {
        rightDoorID = rightDoor.id;
        leftDoorID = rightDoor.leftId;
    } else {
        rightDoor = Object.values(doors).find((door) => door.id === id && door.leftId !== null && !door.deleted);

        if (rightDoor) {
            const leftDoor = Object.values(doors).find((door) => door.id === rightDoor?.leftId && !door.deleted);
            
            if (leftDoor) {
                rightDoorID = rightDoor.id;
                leftDoorID = rightDoor.leftId;
            }
        }
    }

    const currentDoorIndex = doorKeys.indexOf(id);
    const nextDoorId = doorKeys[currentDoorIndex + 1];

    const isActiveLeftDoor = id === leftDoorID ? true : false;
    const isActiveRightDoor = id === rightDoorID ? true : false;

    const debouncedDoorRef = useDebounce(doorRef, 500);

    const handleFirstPhoto = async (event) => {
        const { files } = event.target;

        setFirstPhotoPreview(URL.createObjectURL(files[0]));

        await saveDoorPhoto(id, surveyId, 'photograph_1', files[0]);

        const formData = new FormData();
        formData.append(doorSections.PHOTOGRAPH_ONE, files[0]);

        dispatch(savePhoto(id, formData));
    };

    const handleSecondPhoto = async (event) => {
        const { files } = event.target;

        setSecondPhotoPreview(URL.createObjectURL(files[0]));
        
        await saveDoorPhoto(id, surveyId, 'photograph_2', files[0]);

        const formData = new FormData();
        formData.append(doorSections.PHOTOGRAPH_TWO, files[0]);

        dispatch(savePhoto(id, formData));
    };

    const handleRemovePhoto = (id, type) => {
        removeDoorPhoto(id, type);
        
        if (type === 'photograph_1') {
            dispatch(removePhoto(id, doorSections.PHOTOGRAPH_ONE));
            setFirstPhotoPreview(null);
        } else {
            dispatch(removePhoto(id, doorSections.PHOTOGRAPH_TWO));
            setSecondPhotoPreview(null);
        }
    }

    const nextQuestion = () => {
        if (activeSection) {
            const index = sections.findIndex((question) => question.id === activeSection.id);
            let nextIndex = 0;
    
            if (sections.length > index + 1) {
                nextIndex = index + 1;
                setActiveSection(sections[nextIndex]);
            } else {
                setActiveSection(null);
            }
        }
    }

    const handleOtherInfoSave = () => {
        dispatch(saveOther(id, otherText, otherCost));
    }

    const handleAnswerQuestion = (sectionId, answer) => {
        dispatch(saveSection(id, sectionId, answer));
        nextQuestion();
    }

    const handleFailedOption = (sectionId, option) => {
        dispatch(saveFailedOption(id, sectionId, option));
    }
    
    const handleSaveFailedOther = (sectionId, other) => {
        dispatch(saveSection(id, sectionId, answers.FAILED));
        dispatch(saveFailedOther(id, sectionId, other));
        nextQuestion();
    }

    const handleCloseQuestion = () => {
        setActiveSection(null);
        setShowControls(false);
    }

    const handleDoorType = (type) => {
        setDoorType(type);

        if ((type === '318' || type === '320') && !rightDoor) {
            const rightDoorExists = Object.values(doors).find((door) => door.leftId === id && door.deleted);

            if (rightDoorExists) {
                dispatch(restoreDoor(rightDoorExists.id));
            } else {
                dispatch(addDoor(id, type, doorRef));
            }
        }

        if ((type === '317' || type === '319') && rightDoor) {
            dispatch(removeDoor(rightDoor.id));
        }

        dispatch(saveType(id, type));
    }

    useEffect(() => {
        if (isCompleted) {
            if (nextDoorId) {
                setNextDoor(true);
            }
            
            setFinishDoor(true);
            setShowControls(false);
        }
    }, [nextDoorId, isCompleted, id]);

    useEffect(() => {
        const unansweredQuestions = doors[id].answers.filter((answer) => answer.answer === answers.NOT_ANSWERED);

        if (unansweredQuestions.length > 0) {
            setIsCompleted(false);
        } else {
            setIsCompleted(true);
        }
    }, [doors, id]);

    useEffect(() => {
        setActiveSection(null);
    }, [id]);

    useEffect(() => {
        if (debouncedDoorRef) {
            dispatch(saveRef(id, debouncedDoorRef));
        }
    }, [debouncedDoorRef]);

    useEffect(() => {
        console.log(currentDoor);
        if (currentDoor) {
            setDoorRef(currentDoor?.ref === id ? '' : currentDoor?.ref);
            setDoorType(currentDoor?.type || '');
            setOtherText(currentDoor.other.text);
            setOtherCost(currentDoor.other.cost);
        }
    }, [currentDoor]);

    useEffect(() => {
        if (activeSection) {
            setShowControls(true);
        } else {
            setShowControls(false);
        }
    }, [activeSection]);

    useEffect(() => {
        async function loadPhotos() {
            const db = new Localbase('napfis-webapp');

            try {
                const document1 = await db.collection('door-photos').doc({ id: id, name: 'photograph_1' }).get();
                const document2 = await db.collection('door-photos').doc({ id: id, name: 'photograph_2' }).get();
        
                if (document1) {
                    setFirstPhotoPreview(URL.createObjectURL(document1.photo));
                }

                if (document2) {
                    setSecondPhotoPreview(URL.createObjectURL(document2.photo));
                }
            } catch (error) {
                console.error(error);
            }
        }

        loadPhotos();
    }, []);

    useEffect(() => {
        if (saveToLocalStorage) {
            dispatch(saveDoorToLocalStorage());
        }
    }, [saveToLocalStorage]);

    return (
        <>
            <Header />
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div>
                    {showControls && activeSection && (
                        <DoorViewQuestion section={activeSection} doorId={id} onAnswerQuestion={handleAnswerQuestion} onFailedOption={handleFailedOption} onFailedOther={handleSaveFailedOther} onShowControls={handleCloseQuestion} />
                    )}
                    {/* Door Image */}
                    
                    <div className={styles.doorContainer}>
                        <div className={styles.doorViewContainer}>
                            {isRightDoor && leftDoorID && (
                                <Link to={`/survey/doors/${leftDoorID}`}>
                                    <div className={styles.leftDoorNext}>
                                        <img src={`data:image/jpeg;base64,${DOOR_LEFT_NEXT}`} alt="Left door next" />
                                    </div>
                                </Link>
                            )}
                            <div className={styles.imageContainer}>
                                {isRightDoor ? (
                                    <>
                                        <img src={`data:image/jpeg;base64,${DOOR_RIGHT}`} alt="Right door" />
                                        {RIGHT_DOOR_MARKERS.map((marker) => {
                                            const currentAnswer = doorAnswers.find((answer) => answer.id === marker.id)?.answer;
                                            return marker.id === 572 ? (
                                                <div key={marker.id} className={`${styles.clickableTriangle} ${currentAnswer}-triangle`} style={{ top: marker.top, left: marker.left }} onClick={() => setActiveSection(sections.find((section) => section.id === marker.id))}>
                                                    {currentAnswer === 'NA' && <span className={styles.iconStartPosition}>NA</span>}
                                                    <svg height="100" width="100">
                                                        <polygon points="50,20 16,80 84,80" class="triangle" />
                                                    </svg>
                                                    {currentAnswer === 'NOT_ANSWERED' && <span className={styles.startPosition}>Start</span>}
                                                    {currentAnswer === 'PASSED' && <FontAwesomeIcon className={styles.iconStartPosition} icon={faCheck} />}
                                                    {currentAnswer === 'FAILED' && <FontAwesomeIcon className={styles.iconStartPosition} icon={faTimes} />}
                                                </div>
                                                ) : (
                                                    <div key={marker.id} className={`${styles.clickable} ${currentAnswer} ${marker.id === activeSection?.id ? styles.activeMarker : ''}`} style={{ top: marker.top, left: marker.left }} onClick={() => setActiveSection(sections.find((section) => section.id === marker.id))}>
                                                        {currentAnswer === 'PASSED' && <FontAwesomeIcon icon={faCheck} />}
                                                        {currentAnswer === 'FAILED' && <FontAwesomeIcon icon={faTimes} />}
                                                        {currentAnswer === 'NA' && <span>NA</span>}
                                                    </div>
                                                )
                                            })}
                                    </>
                                ) : (
                                    <>
                                        <img src={`data:image/jpeg;base64,${DOOR_LEFT}`} alt="Left door" />
                                        {LEFT_DOOR_MARKERS.map((marker) => {
                                            const currentAnswer = doorAnswers.find((answer) => answer.id === marker.id)?.answer;
                                            return marker.id === 572 ? (
                                                <div key={marker.id} className={`${styles.clickableTriangle} ${currentAnswer}-triangle`} style={{ top: marker.top, left: marker.left }} onClick={() => setActiveSection(sections.find((section) => section.id === marker.id))}>
                                                    {currentAnswer === 'NA' && <span className={styles.iconStartPosition}>NA</span>}
                                                    <svg height="100" width="100">
                                                        <polygon points="50,20 16,80 84,80" class="triangle" />
                                                    </svg>
                                                    {currentAnswer === 'NOT_ANSWERED' && <span className={styles.startPosition}>Start</span>}
                                                    {currentAnswer === 'PASSED' && <FontAwesomeIcon className={styles.iconStartPosition} icon={faCheck} />}
                                                    {currentAnswer === 'FAILED' && <FontAwesomeIcon className={styles.iconStartPosition} icon={faTimes} />}
                                                </div>
                                            ) : (
                                                <div key={marker.id} className={`${styles.clickable} ${currentAnswer} ${marker.id === activeSection?.id ? styles.activeMarker : ''}`} style={{ top: marker.top, left: marker.left }} onClick={() => setActiveSection(sections.find((section) => section.id === marker.id))}>
                                                    {currentAnswer === 'PASSED' && <FontAwesomeIcon icon={faCheck} />}
                                                    {currentAnswer === 'FAILED' && <FontAwesomeIcon icon={faTimes} />}
                                                    {currentAnswer === 'NA' && <span>NA</span>}
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                            {!isRightDoor && rightDoorID && (
                                <Link to={`/survey/doors/${rightDoorID}`}>
                                    <div className={styles.rightDoorNext}>
                                        <img src={`data:image/jpeg;base64,${DOOR_RIGHT_NEXT}`} alt="Right door next" />
                                    </div>
                                </Link>
                            )}
                        </div>
                        {/* Door Buttons */}
                        <div className={styles.doorButtons}>
                            <div>
                                <div className={styles.fieldBlock}>
                                    <input type="text" value={doorRef} placeholder="Door Reference" onChange={(e) => setDoorRef(e.target.value)} disabled={isActiveRightDoor} />
                                </div>
                                <div className={styles.fieldBlock} style={{ marginTop: '1rem' }}>
                                    <select value={doorType} onChange={(e) => handleDoorType(e.target.value)} disabled={isActiveRightDoor}>
                                        <option value="">Please select a door type</option>
                                        <option value="317">FD30/s Single</option>
                                        <option value="318">FD30/s Double</option>
                                        <option value="319">FD60/s Single</option>
                                        <option value="320">FD60/s Double</option>
                                    </select>
                                </div>
                            
                                <div className={styles.doorSelectButtons}>
                                    {rightDoorID && (
                                        <>
                                            <Link className={`${isActiveLeftDoor && styles.active} ${styles.doorButton}`} to={`/survey/doors/${leftDoorID}`}>Left</Link>
                                            <Link className={`${isActiveRightDoor && styles.active} ${styles.doorButton}`} to={`/survey/doors/${rightDoorID}`}>Right</Link>
                                        </>
                                    )}
                                    
                                </div>
                                {otherInfo && (
                                    <div>
                                        <div className={styles.fieldBlock} style={{ marginTop: '1rem' }}>
                                            <textarea className={styles.textArea} value={otherText} placeholder="Any additional comments & costs" onChange={(e) => setOtherText(e.target.value)} />
                                        </div>
                                        <div style={{ display: 'flex', marginTop: '1rem', alignItems: 'center' }}>
                                            <span style={{ marginRight: '0.5rem' }}>&pound;</span>
                                            <div className={styles.fieldBlock}>
                                                <input type="number" min={0} value={otherCost} onChange={(e) => setOtherCost(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className={styles.buttonContainer}>
                                            <button className={styles.otherInfoButton} type="button" onClick={handleOtherInfoSave} disabled={otherLoading}>Save Other Info <PulseLoader loading={otherLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={styles.doorButtonsBottom}>
                                <div className={styles.photographContainer}>
                                    <img src={`data:image/jpeg;base64,${PHOTOGRAPH_ONE}`} alt="Upload first photograph" style={{ marginRight: '1rem' }} />
                                    <input className={styles.photograph} type="file" onChange={handleFirstPhoto} placeholder="Choose File" disabled={isActiveRightDoor} />
                                    {firstPhotoPreview && (
                                        <div className={styles.previewContainer}>
                                            <img className={styles.preview} src={firstPhotoPreview} alt="First photograph" />
                                            <button className={styles.removePhoto} onClick={() => handleRemovePhoto(id, 'photograph_1')}>X</button>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.photographContainer} style={{ marginTop: '1rem' }}>
                                    <img src={`data:image/jpeg;base64,${PHOTOGRAPH_TWO}`} alt="Upload second photograph" style={{ marginRight: '1rem' }} />
                                    <input className={styles.photograph} type="file" onChange={handleSecondPhoto} placeholder="Choose File" disabled={isActiveRightDoor} />
                                    {secondPhotoPreview && (
                                        <div className={styles.previewContainer}>
                                            <img className={styles.preview} src={secondPhotoPreview} alt="First photograph" />
                                            <button className={styles.removePhoto} onClick={() => handleRemovePhoto(id, 'photograph_2')}>X</button>
                                        </div>
                                    )}
                                </div>
                                {isCompleted && (
                                    <div className={styles.buttonContainer}>
                                        <button className={styles.otherInfoButton} style={{ marginTop: '2rem' }} type="button" onClick={() => setOtherInfo(!otherInfo)}>Other Info</button>
                                        {nextDoor && nextDoorId && (
                                            <Link className={styles.nextDoorButton} style={{ marginTop: '2rem' }} to={`/survey/doors/${nextDoorId}`}>Next Door</Link>
                                        )}
                                        {finishDoor && (
                                            <button className={styles.finishButton} style={{ marginTop: '2rem' }} type="button" onClick={() => history.push('/survey/doors/')}>Finish Door</button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <BottomNav />
        </>
    )       
}
