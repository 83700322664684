import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { addDoor, removeDoor, saveDoorToLocalStorage } from '../../actions/doorActions';
import { getDependencies } from '../../actions/appActions';
import answers from '../../constants/answers';

import './styles/DoorList.css';

export const DoorList = () => {
    const { search } = useLocation();
    const [disabled, setDisabled] = useState(false);
    const [showError, setShowError] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [doorToRemove, setDoorToRemove] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const surveySelector = useSelector((state) => state.survey);
    const { totalFields } = surveySelector;
    
    const doorSelector = useSelector((state) => state.door);
    const { doors: doorsObj, loading, saveToLocalStorage } = doorSelector;

    const doors = doorsObj ? Object.values(doorsObj).filter((d) => !d.deleted) : [];

    const errors = doors.map((door) => !door.deleted && door.answers.filter((answer) => answer.answer === answers.NOT_ANSWERED));

    const handleRemoveDoor = (doorId) => {
        setConfirmation(true);
        setDoorToRemove(doorId);
    }

    const handleRemoveDoorConfirm = (doorId) => {
        dispatch(removeDoor(doorId));
        setConfirmation(false);
        setDoorToRemove(null);
    }

    const handleRemoveDoorCancel = () => {
        setConfirmation(false);
        setDoorToRemove(null)
    }

    const handleProceedToSummary = () => {
        let hasErrors = false;

        errors.forEach((error) => {
            if (error.length > 0) hasErrors = true;
        });

        if (hasErrors) {
            setShowError(true);
        } else {
            setShowError(false);
            history.push('/survey/summary');
        } 
    }

    useEffect(() => {
        dispatch(getDependencies());
    }, []);

    useEffect(() => {
        if (saveToLocalStorage) {
            dispatch(saveDoorToLocalStorage());
        }
    }, [saveToLocalStorage])

    return (
        <Fragment>
            <Header>Door Surveys</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div className="doorList">
                    <p style={{ textAlign: 'center' }}>Browse through each door and complete a series of checks to determine if the doors are compliant/meet regulations. Once you've completed each door, click process survey.</p>
                    <div className="form__buttons" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <button className="form__button secondary addDoor" onClick={() => dispatch(addDoor())}>Add Door</button>
                        </div>
                    </div>
                    {!loading ? doors.length > 0 ? doors.map((door) => {
                        let errorClass = door.answers.filter((answer) => answer.answer === answers.NOT_ANSWERED).length < 1 ? 'field-completed' : 'field-incompleted';

                        if (errorClass === 'field-completed') {
                            const rightDoorExists = doors.find((d) => d.leftId === door.id);

                            if (rightDoorExists) {
                                errorClass = rightDoorExists.answers.filter((a) => a.answer === answers.NOT_ANSWERED).length < 1 ? 'field-completed' : 'field-incompleted';
                            }
                        }
                
                        if (!door.deleted && !door.leftId) {
                            return (
                                <div key={door.id} className={`doorList__container ${errorClass} ${showError && 'show'}`}>
                                    <div className="doorList__content">
                                        <span>{door?.ref}</span>
                                        {door?.completedFields === totalFields && (
                                            <span className="door-complete">
                                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg>
                                            </span>
                                        )}
                                    </div>
                                    <Link className="edit" to={`/survey/doors/${door?.id}`} style={{ marginRight: '1rem' }}>Edit</Link>
                                    <button className="remove" onClick={() => handleRemoveDoor(door.id)}>Remove</button>
                                </div>
                            );
                        }

                        return null;
                    }) : (
                        <Fragment>
                            <div style={{ textAlign: 'center', marginTop: '1rem' }}>No doors set. Start adding doors by clicking the 'Add Door' button.</div>
                        </Fragment>
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>Loading...</div>
                    )}
                    {(showError || search.includes('error')) && (<div style={{ textAlign: 'center', marginTop: '1rem' }}>You have an incomplete door inspection. Please rectify and save your doors to continue.</div>)}
                    <div className="form__buttons">
                        <button style={{ marginRight: '1rem' }} className="form__button secondary form__button--inline" onClick={() => history.push('/survey/edit')}>Edit Client Form</button>
                        <button className="form__button form__button--inline" onClick={handleProceedToSummary} disabled={doors.length < 1}>Process Survey</button>
                    </div>
                    {confirmation && (
                        <div class="popup">
                            <p className="title">Are you sure you want to delete this door?</p>
                            <div className="form__buttons">
                                <button onClick={handleRemoveDoorCancel} className="form__button secondary">No</button>
                                <button onClick={() => handleRemoveDoorConfirm(doorToRemove)} className="form__button primary">Yes</button>
                            </div>
                        </div>
                    )}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
