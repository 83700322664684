export const SAVE_SECTION_REQUEST = 'SAVE_SECTION_REQUEST';
export const SAVE_SECTION_SUCCESS = 'SAVE_SECTION_SUCCESS';
export const SAVE_SECTION_FAILURE = 'SAVE_SECTION_FAILURE';

export const ADD_DOOR_REQUEST = 'ADD_DOOR_REQUEST';
export const ADD_DOOR_SUCCESS = 'ADD_DOOR_SUCCESS';
export const ADD_DOOR_FAILURE = 'ADD_DOOR_FAILURE';

export const SAVE_DOOR_REQUEST = 'SAVE_DOOR_REQUEST';
export const SAVE_DOOR_SUCCESS = 'SAVE_DOOR_SUCCESS';

export const SAVE_DOOR_REF_REQUEST = 'SAVE_DOOR_REF_REQUEST';
export const SAVE_DOOR_REF_SUCCESS = 'SAVE_DOOR_REF_SUCCESS';
export const SAVE_DOOR_REF_FAILURE = 'SAVE_DOOR_REF_FAILURE';

export const SAVE_DOOR_TYPE_REQUEST = 'SAVE_DOOR_TYPE_REQUEST';
export const SAVE_DOOR_TYPE_SUCCESS = 'SAVE_DOOR_TYPE_SUCCESS';
export const SAVE_DOOR_TYPE_FAILURE = 'SAVE_DOOR_TYPE_FAILURE';

export const SAVE_DOOR_OTHER_REQUEST = 'SAVE_DOOR_OTHER_REQUEST';
export const SAVE_DOOR_OTHER_SUCCESS = 'SAVE_DOOR_OTHER_SUCCESS';

export const REMOVE_DOOR_REQUEST = 'REMOVE_DOOR_REQUEST';
export const REMOVE_DOOR_SUCCESS = 'REMOVE_DOOR_SUCCESS';

export const RESTORE_DOOR_REQUEST = 'RESTORE_DOOR_REQUEST';
export const RESTORE_DOOR_SUCCESS = 'RESTORE_DOOR_SUCCESS';

export const SAVE_DOOR_PHOTO_REQUEST = 'SAVE_DOOR_PHOTO_REQUEST';
export const SAVE_DOOR_PHOTO_SUCCESS = 'SAVE_DOOR_PHOTO_SUCCESS';

export const REMOVE_DOOR_PHOTO_REQUEST = 'REMOVE_DOOR_PHOTO_REQUEST';
export const REMOVE_DOOR_PHOTO_SUCCESS = 'REMOVE_DOOR_PHOTO_SUCCESS';

export const CLEAR_DOORS = 'CLEAR_DOORS';
export const LOAD_DOORS = 'LOAD_DOORS';

export const SAVE_TO_LOCALSTORAGE_REQUEST = 'SAVE_TO_LOCALSTORAGE_REQUEST';
export const SAVE_TO_LOCALSTORAGE_SUCCESS = 'SAVE_TO_LOCALSTORAGE_SUCCESS';

export const SAVE_FAILED_OPTION_REQUEST = 'SAVE_FAILED_OPTION_REQUEST';
export const SAVE_FAILED_OPTION_SUCCESS = 'SAVE_FAILED_OPTION_SUCCESS';

export const SAVE_FAILED_OTHER_REQUEST = 'SAVE_FAILED_OTHER_REQUEST';
export const SAVE_FAILED_OTHER_SUCCESS = 'SAVE_FAILED_OTHER_SUCCESS';