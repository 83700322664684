import { GET_RECENT_SURVEYS_REQUEST, GET_RECENT_SURVEYS_SUCCESS, GET_RECENT_SURVEYS_FAIL, REMOVE_SURVEY_REQUEST, REMOVE_SURVEY_SUCCESS, REMOVE_SURVEY_FAILURE } from '../types/dashboardTypes';

const recentSurveys = JSON.parse(localStorage.getItem('recentSurveys'));
const savedSurveys = JSON.parse(localStorage.getItem('survey'));
const clientData = JSON.parse(localStorage.getItem('clientData'));
let localSurveys = [];

if (savedSurveys) {
    const surveyKeys = Object.keys(savedSurveys).filter((key) => savedSurveys[key]?.type === 'offline');

    surveyKeys.forEach((key) => {
        let data = {};
        let doors = 0;
        if (savedSurveys[key]?.doors) {
            doors = Object.keys(savedSurveys[key]?.doors).length;
        }

        if (clientData[key]) {
            data = clientData[key];
        }
        localSurveys.push({ ...data, surveyId: key, type: 'offline', inspection_doors: doors, survey_status: 'In Progress' });
    });
}

const initialState = {
    recentSurveys: [],
    localSurveys: [],
    loading: false,
    removeSurveyLoading: false,
    errors: null,
    removeSurveyErrors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_RECENT_SURVEYS_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null,
                recentSurveys: [],
                localSurveys: [],
            }
        case GET_RECENT_SURVEYS_SUCCESS:
            return {
                ...state,
                loading: false,
                recentSurveys: [...action.payload.recent],
                localSurveys: [...action.payload.local],
            }
        case GET_RECENT_SURVEYS_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                recentSurveys: [...action.payload.recent],
                localSurveys: [...action.payload.local],
            }
        case REMOVE_SURVEY_REQUEST:
            return {
                ...state,
                removeSurveyLoading: true,
                removeSurveyErrors: null,
            }
        case REMOVE_SURVEY_SUCCESS:
            return {
                ...state,
                recentSurveys: state.recentSurveys.filter((survey) => survey.survey_secret_key !== action.payload),
                removeSurveyLoading: false,
                removeSurveyErrors: null,
            }
        default:
            return state;
    }
};