import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import Input from '../../components/forms/Input';

import { getCosts, setCosts } from '../../actions/costsActions';

import './styles/Costs.css';

export const Costs = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const costsSelector = useSelector((state) => state.costs);
    const [formData, setFormData] = useState(null);
    const [formFields, setFormFields] = useState([]);
    const { costs, form } = costsSelector;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({...formData, [name]: value });
    }

    const onSubmit = (event) => {
        event.preventDefault();

        dispatch(setCosts(formData));
        history.push('/');
    }

    useEffect(() => {
        dispatch(getCosts());
    }, []);

    useEffect(() => {
        if (formFields) {
            let formTmp = {};
            
            if (costs) {
                for (const [key, value] of Object.entries(costs)) {
                    if (formFields.includes(key)) {
                        formTmp[key] = value;
                    }
                }
            }
    
            setFormData(formTmp);
        }
    }, [costs, formFields]);

    useEffect(() => {
        const tmpFields = form.map((field) => {
            return field?.name
        });

        setFormFields(tmpFields);
    }, [form]);

    return (
        <Fragment>
            <Header>Costs Set Up</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <h2 style={{ textAlign: 'center' }}>Please specify the costs applicable for any door repairs</h2>
                <form className="costsForm" onSubmit={onSubmit}>
                    <Fragment>
                        {form?.map((field) => {
                            let value = '';

                            if (costs) {
                                value = costs[field?.name] || '';
                            }
                            
                            return (
                                <>
                                    {field?.section_label && (<div className="form__title">{field?.section_label}</div>)}
                                    <Input field={field} value={value} handleChange={handleChange} />
                                </>
                            )
                        })}
                        <div className="form__buttons">
                            <button className="form__button" type="submit">Save &amp; Continue</button>
                        </div>
                    </Fragment>
                </form>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
