import {
    LOAD_DOORS,
    SAVE_SECTION_REQUEST,
    SAVE_SECTION_SUCCESS,
    SAVE_DOOR_OTHER_REQUEST,
    SAVE_DOOR_OTHER_SUCCESS,
    ADD_DOOR_REQUEST,
    ADD_DOOR_SUCCESS,
    SAVE_DOOR_REF_SUCCESS,
    SAVE_DOOR_REF_REQUEST,
    SAVE_DOOR_TYPE_REQUEST,
    SAVE_DOOR_TYPE_SUCCESS,
    CLEAR_DOORS,
    REMOVE_DOOR_REQUEST,
    REMOVE_DOOR_SUCCESS,
    RESTORE_DOOR_REQUEST,
    RESTORE_DOOR_SUCCESS,
    SAVE_TO_LOCALSTORAGE_REQUEST,
    SAVE_TO_LOCALSTORAGE_SUCCESS
} from "../types/doorTypes";

let doors = {};
const savedDoors = JSON.parse(localStorage.getItem('doors'));
const activeSurvey = localStorage.getItem('activeSurvey');

if (savedDoors) {
    doors = savedDoors[activeSurvey] || {};
}

const initialState = {
    doors,
    loading: false,
    refLoading: false,
    otherLoading: false,
    saveToLocalStorage: false,
    error: null,
    refError: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_DOORS:
            return {
                ...state,
                doors: action.payload,
            }
        case CLEAR_DOORS:
            return {
                ...state,
                loading: false,
                doors: {},
                error: null,
            }
        case ADD_DOOR_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case ADD_DOOR_SUCCESS: 
            return {
                ...state,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...action.payload,
                    }
                },
                loading: false
            }
        case SAVE_SECTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case SAVE_SECTION_SUCCESS:
            return {
                ...state,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        ...action.payload
                    }
                },
                loading: false,
            }
        case SAVE_DOOR_REF_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case SAVE_DOOR_REF_SUCCESS:
            let rightDoor = Object.values(state.doors).find((door) => door.leftId === action.payload.id);

            if (rightDoor) {
                return {
                    ...state,
                    doors: {
                        ...state.doors,
                        [action.payload.id]: {
                            ...state.doors[action.payload.id],
                            ref: action.payload.ref,
                        },
                        [rightDoor.id]: {
                            ...state.doors[rightDoor.id],
                            ref: action.payload.ref,
                        }
                    },
                    loading: false,
                }
            }

            return {
                ...state,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        ref: action.payload.ref,
                    }
                },
                loading: false,
            }
        case SAVE_DOOR_TYPE_REQUEST:
            return {
                ...state,
                refLoading: true,
            }
        case SAVE_DOOR_TYPE_SUCCESS:
            return {
                ...state,
                refLoading: false,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        type: action.payload.type,
                    }
                }
            }
        case SAVE_DOOR_OTHER_REQUEST:
            return {
                ...state,
                otherLoading: true,
            }
        case SAVE_DOOR_OTHER_SUCCESS:
            return {
                ...state,
                otherLoading: false,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        other: action.payload.other,
                    }
                }
            }
        case REMOVE_DOOR_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case REMOVE_DOOR_SUCCESS:
            rightDoor = Object.values(state.doors).find((door) => door.leftId === action.payload);

            if (rightDoor) {
                return {
                    ...state,
                    loading: false,
                    doors: {
                        ...state.doors,
                        [action.payload]: {
                            ...state.doors[action.payload],
                            deleted: true,
                        },
                        [rightDoor.id]: {
                            ...state.doors[rightDoor.id],
                            deleted: true,
                        }
                    }
                }
            }

            return {
                ...state,
                loading: false,
                doors: {
                    ...state.doors,
                    [action.payload]: {
                        ...state.doors[action.payload],
                        deleted: true,
                    }
                }
            }
        case RESTORE_DOOR_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case RESTORE_DOOR_SUCCESS:
            return {
                ...state,
                loading: false,
                doors: {
                    ...state.doors,
                    [action.payload]: {
                        ...state.doors[action.payload],
                        deleted: false,
                    }
                }
            }
        case SAVE_TO_LOCALSTORAGE_REQUEST:
            return {
                ...state,
                saveToLocalStorage: true,
            }
        case SAVE_TO_LOCALSTORAGE_SUCCESS:
            return {
                ...state,
                saveToLocalStorage: false,
            }
        default:
            return state;
    }
};