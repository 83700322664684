import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Dashboard from './pages/Dashboard/Dashboard';
import { DoorInspection } from './pages/DoorInspection';
import { InspectionForm } from './components/InspectionForm';
import { Costs } from './pages/Costs';
import { DoorList } from './components/DoorList';
import { DoorView } from './components/DoorView';
import { SurveySummary } from './components/SurveySummary';
import { SurveyComplete } from './components/SurveyComplete';
import { Help } from './pages/Help';
import { DataManagement } from './pages/DataManagement';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ProtectedRoute from './components/routes/ProtectedRoute';

import { getMenu } from './actions/appActions'; 

import './App.css';

export const App = () => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const { isAuthenticated } = authSelector;
  const appSelector = useSelector((state) => state.app);
  const { showBackground } = appSelector;

  // Temp solution until server can be access through localhost
  const TestRoute = process.env.NODE_ENV === 'development' ? Route : ProtectedRoute;

  useEffect(() => {
    dispatch(getMenu());
    // dispatch(checkSubscription());
  }, [dispatch]);

  return (
    <Router>
      <div className={`app${window.location.pathname === '/login' ? ' no-transition' : ''}${showBackground ? ' popup-background' : ''}`}>
        <Switch>
          <TestRoute path="/" exact component={Dashboard} isAuthenticated={isAuthenticated} />
          <TestRoute path="/survey" exact component={DoorInspection} isAuthenticated={isAuthenticated} />
          <TestRoute path="/survey/new" isAuthenticated={isAuthenticated}>
            <InspectionForm showEdit={false} />
          </TestRoute>
          <TestRoute path="/survey/edit" isAuthenticated={isAuthenticated}>
            <InspectionForm showEdit={true} />
          </TestRoute>
          <TestRoute path="/survey/doors" exact component={DoorList} isAuthenticated={isAuthenticated} />
          <TestRoute path="/survey/doors/:id" component={DoorView} isAuthenticated={isAuthenticated} />
          <TestRoute path="/survey/summary" component={SurveySummary} isAuthenticated={isAuthenticated} />
          <TestRoute path="/survey/complete" component={SurveyComplete} isAuthenticated={isAuthenticated} />
          {/* <TestRoute path="/survey/floorplan" component={PDFImageSelector} isAuthenticated={isAuthenticated} /> */}
          <TestRoute path="/costs" component={Costs} isAuthenticated={isAuthenticated} />
          <Route path="/help" component={Help} />
          <Route path="/data" component={DataManagement} />
          <Route path="/login" component={Login} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/logout" component={Logout} />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
}

