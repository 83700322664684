import axios from 'axios';
import Localbase from 'localbase';
import _ from 'lodash';

export const handleForm = async (url, id, data) => {
    const fd = new FormData();

    for (const [key, value] of Object.entries(data)) {
        if (value.type === 'file') {
            if (typeof value.value === 'object') {
                // console.log('value: ' + value.value);
                // console.log('name: ' + value.value.name);
                fd.append(key, value.value, value.value.name);
            } else {
                fd.append(key, value.value);
            }
        } else {
            fd.append(key, value.value);
        }
    }

    const response = await axios.post(url, fd, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Record-Id': id,
            'Wg-Method': 'SAVE_DOOR'
        }
    });

    return response;
}

export const clearSurvey = async () => {
    const surveyId = localStorage.getItem('activeSurvey');

    if (surveyId) {
        let survey = JSON.parse(localStorage.getItem('survey'));

        if (survey) {
            if (survey[surveyId]) {
                delete survey[surveyId];
            }
            
            if (_.isEmpty(survey)) {
                localStorage.removeItem('survey');
            } else {
                localStorage.setItem('survey', JSON.stringify(survey));
            }
        }

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            if (clientData[surveyId]) {
                delete clientData[surveyId];
            }

            if (_.isEmpty(clientData)) {
                localStorage.removeItem('clientData');
            } else {
                localStorage.setItem('clientData', JSON.stringify(clientData));
            }
        }

        let doorCosts = JSON.parse(localStorage.getItem('doorCosts'));

        if (doorCosts) {
            if (doorCosts[surveyId]) {
                delete doorCosts[surveyId];
            }

            if (_.isEmpty(doorCosts)) {
                localStorage.removeItem('doorCosts');
            } else {
                localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
            }
        }

        let doorScores = JSON.parse(localStorage.getItem('doorScores'));

        if (doorScores) {
            if (doorScores[surveyId]) {
                delete doorScores[surveyId];
            }

            if (_.isEmpty(doorScores)) {
                localStorage.removeItem('doorScores');
            } else {
                localStorage.setItem('doorScores', JSON.stringify(doorScores));
            }
        }

        let summary = JSON.parse(localStorage.getItem('summary'));

        if (summary) {
            if (summary[surveyId]) {
                delete summary[surveyId];
            }

            if (_.isEmpty(summary)) {
                localStorage.removeItem('summary');
            } else {
                localStorage.setItem('summary', JSON.stringify(summary));
            }
        }

        let costsActive = JSON.parse(localStorage.getItem('costsActive'));

        if (costsActive) {
            if (costsActive[surveyId]) {
                delete costsActive[surveyId];
            }

            if (_.isEmpty(costsActive)) {
                localStorage.removeItem('costsActive');
            } else {
                localStorage.setItem('costsActive', JSON.stringify(costsActive));
            }
        }

        const db = new Localbase('napfis-webapp');

        try {
            await db.collection('door-photos').doc({ surveyId: surveyId }).delete();
        } catch (error) {
            console.error(error);
        }
    }

    let doors = JSON.parse(localStorage.getItem('doors'));

    if (doors) {
        if (doors[surveyId]) {
            delete doors[surveyId];
        }

        if (_.isEmpty(doors)) {
            localStorage.removeItem('doors');
        } else {
            localStorage.setItem('doors', JSON.stringify(doors));
        }
    }

    localStorage.removeItem('activeSurvey');
}

export const clearAllSurveys = async () => {
    localStorage.removeItem('survey');
    localStorage.removeItem('clientData');
    localStorage.removeItem('doorCosts');
    localStorage.removeItem('doorScores');
    localStorage.removeItem('summary');
    localStorage.removeItem('doors');
    localStorage.removeItem('dependencies');

    localStorage.removeItem('activeSurvey');

    const db = new Localbase('napfis-webapp');

    try {
        await db.delete();
    } catch (error) {
        
    }
}

export const saveDoorPhoto = async (id, surveyId, name, photo) => {
    const db = new Localbase('napfis-webapp');

    try {
        const document = await db.collection('door-photos').doc({ id: id, name: name }).get();

        if (document) {
            await db.collection('door-photos').doc({ id: id, name: name }).delete();
        }

        await db.collection('door-photos').add({
            id: id,
            surveyId,
            name: name,
            photo
        });

    } catch (error) {
        console.error(error);
    }
}

export const removeDoorPhoto = async (id, name) => {
    const db = new Localbase('napfis-webapp');

    try {
        await db.collection('door-photos').doc({ id: id, name: name }).delete();
    } catch (error) {
        console.error(error);
    }
}
